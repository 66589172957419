
$(function(){

  //判定
  if($('[data-js="sliderLink"]').length) {
    sliderLink();
  }
  if($('[data-js="slick_news"]').length) {
    sliderNews();
  }

  function sliderNews() {
    $('[data-js="slick_news"]').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: true,
      dots: false,
      arrow: false,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            arrow: true,
          }
        }
      ]
    })
  }

  function sliderLink() {
    $('[data-js="sliderLink"]').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: true,
      dots: true,
      arrow: true,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    })
  }

});